import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Section from '../Section'
import './NewsletterForm.scss'

export default class NewsletterForm extends React.Component {

  state = {
    emailAddress: "",
    firstName: "",
    lastName: "",
    errorEmail: true,
    errorFirstName: true,
    errorLastName: true,
    emailSubmitted: false,
    subscriptionResponse: ""
  }

  validateEmailAddress = address => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(address) || address === '')  {
      return false
    }
    return true
  }

  validateName = name => {
    if (/^[a-z ,.'-]+$/i.test(name) || name === '')  {
      return false
    }
    return true
  }

  sendUserToMailChimp = () => {
    const {
      emailAddress, firstName, lastName
    } = this.state

    axios.post('/api/mailchimp', {emailAddress, firstName, lastName})
    .then(res => {
      this.setState({
        subscriptionResponse: res.data.status
      })
    })
  }

  handleEmailSubmit = e => {
    e.preventDefault()
    !this.state.errorEmail ? this.setState({ emailSubmitted:true }) : null
  }

  handleNameSubmit = e => {
    const {
      errorFirstName,
      errorLastName,
      errorEmail
    } = this.state
    e.preventDefault()
    !errorEmail && !errorFirstName && !errorLastName ? this.sendUserToMailChimp() : null
  }

  handleEmailChange = e => {
    const errorEmail = this.validateEmailAddress(e.target.value.trim())
    this.setState({
      emailAddress: e.target.value.trim(),
      errorEmail
    })
  }

  handleFirstNameChange = e => {
    const errorFirstName = this.validateName(e.target.value.trim())
    this.setState({
      firstName: e.target.value,
      errorFirstName
    })
  }

  handleLastNameChange = e => {
    const errorLastName = this.validateName(e.target.value.trim())
    this.setState({
      lastName: e.target.value,
      errorLastName
    })
  }


  requestEmailAddress = () => {
    const {
      emailAddress,
      errorEmail,
      emailSubmitted
    } = this.state;

    return (
      <form className="newsletter-form">
        <div className="columns">
          <div className="column is-full-width">
            <p className="control">
              <input
                className={`input is-medium ${errorEmail && !emailAddress === "" ? "is-danger": null}`}
                type="text"
                placeholder="Enter your e-mail address"
                value={emailAddress}
                onChange={this.handleEmailChange}
                />
              {
                errorEmail && !emailAddress === "" ? (
                  <span className="icon is-small is-right">
                    <i className="fa fa-warning"></i>
                  </span>
                ) : null
              }

            </p>
            {
              /*
              <p className="control">
                <button
                  className="button is-medium is-hidden-touch"
                  disabled={errorEmail  || emailAddress === ""}
                  onClick={this.handleEmailSubmit}
                >
                  Next
                </button>
              </p>

               */
            }
              {
                errorEmail  && !emailAddress === "" ? (
                  <p className="help is-danger">This e-mail address is not valid</p>
                ) : null
              }
          </div>
          <div className="column is-full-width">
            <button
              className="button is-medium"
              disabled={errorEmail  || emailAddress === ""}
              onClick={this.handleEmailSubmit}
            >
              Next
            </button>
          </div>
          {
            /*

            <div className="column control">
                <div className="social-media">
                  <a
                    href="https://www.instagram.com/BotafogoGrill"
                    target="_blank"><i className="fa fa-instagram" /></a>
                  <a
                    href="https://www.facebook.com/BotafogoGrillHuddersfield/"
                    target="_blank"><i className="fa fa-facebook" /></a>
                    <a
                      href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                  <a
                    href="https://twitter.com/BotafogoGrill"
                    target="_blank"><i className="fa fa-twitter" /></a>

                </div>
            </div>

             */
          }

        </div>
      </form>
    )
  }

  requestName = () => {
    const {
      firstName,
      lastName,
      errorFirstName,
      errorLastName,
      emailSubmitted
    } = this.state;

    return (
      <form className="newsletter-form">
        <div className="columns">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-grouped">
                  <p className="control is-expanded">
                    <input
                      className="input is-medium"
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={this.handleFirstNameChange}/>

                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      className="input is-medium"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={this.handleLastNameChange} />

                  </p>
                </div>
              </div>
            </div>
            <button
              className="button is-medium"
              disabled={errorFirstName || errorLastName || firstName === "" || lastName === ""}
              onClick={this.handleNameSubmit}
              >Sign up</button>
          </div>
              {
                /*

                <div className="column control">
                    <div className="social-media">
                      <a
                        href="https://www.instagram.com/BotafogoGrill"
                        target="_blank"><i className="fa fa-instagram" /></a>
                      <a
                        href="https://www.facebook.com/BotafogoGrillHuddersfield/"
                        target="_blank"><i className="fa fa-facebook" /></a>
                        <a
                          href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                      <a
                        href="https://twitter.com/BotafogoGrill"
                        target="_blank"><i className="fa fa-twitter" /></a>
                    </div>
                </div>

                 */
              }
        </div>
      </form>
    )
  }

  renderSignupSuccessful = () => {
    const {
      subscriptionResponse,
      firstName
    } = this.state;

    return (
      <div>
        <h1>Thanks, Michael</h1>
        <p className="response-message">Keep an eye out for our monthly e-newsletters for the latest Botafogo News / Offers.</p>
      </div>
    )
  }
  renderSignupFailed = () => {
    const {
      subscriptionResponse,
      firstName
    } = this.state;

    return (
      <div>
        <h1>Oops, Sorry {firstName}</h1>
        <p className="response-message">We could not sign you up at this time.</p>
      </div>
    )
  }

  renderSubscribed = () => {
    const {
      subscriptionResponse
    } = this.state;

    return subscriptionResponse === "Subscribed" ? this.renderSignupSuccessful() : this.renderSignupFailed()
  }

  renderFreshView = () => {
    const {
      emailSubmitted
    } = this.state;

    return (
      <div>
        <h1>Join our Newsletter</h1>
        {
          emailSubmitted ? this.requestName() : this.requestEmailAddress()
        }
      </div>
    )
  }

  render() {
    return <div />

    return (
    <div className="newsletter-signup">
      <div className="container" style={{marginTop:"12px"}}>
        <div className="columns is-multiline">
          <div className="column">
            {
              this.state.subscriptionResponse ? this.renderSubscribed() : this.renderFreshView()
            }
          </div>
          {
            this.state.subscriptionResponse ? (

                <div className="column">
                    <div className="social-media social-media-submitted">
                      <a
                        href="https://www.instagram.com/BotafogoGrill"
                        target="_blank"><i className="fa fa-instagram" /></a>
                      <a
                        href="https://www.facebook.com/BotafogoGrillHuddersfield/"
                        target="_blank"><i className="fa fa-facebook" /></a>
                        <a
                          href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                      <a
                        href="https://twitter.com/BotafogoGrill"
                        target="_blank"><i className="fa fa-twitter" /></a>
                    </div>
                </div>
            ) : null
          }
        </div>
      </div>
    </div>
    )
  }
}
