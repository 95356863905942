import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const OpeningHours = () => {
  const data = useStaticQuery(graphql`
    query OpeningTimesQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "restaurant-hours" } }) {
        frontmatter {
          services {
            session
            duration
          }
        }
      }
    }
  `);

  const { services = [] } = data.markdownRemark.frontmatter || {}
  const servicesLength = services.length

  return (
    <>
    <h1>We are open</h1>
    {services.map((_, i) => (
      <div key={`service-${i}`}>
        <p>{_.session}</p>
        <div style={{ whiteSpace: 'pre-line' }}>
          {_.duration}
        </div>
        {servicesLength === i + 1 ? null : (
          <hr className="dotted is-bota-purple"></hr>
        )}
      </div>
    ))}
    </>
  )
}

export default OpeningHours