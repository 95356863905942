import React from 'react'
import PropTypes from 'prop-types'
import { Link as NavLink } from 'gatsby'

import Section from '../Section'
import './Nav.scss'

export default class Nav extends React.Component {

  state = {
    isActive: false
  }

  toggleActiveState = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  render() {
    const {
      isActive
    } = this.state

    return (
        <div className="bota-nav">
          <header className="nav">
            <div className="container is-fluid">
              <div className="nav-left">
                <NavLink to="/" className='nav-item is-hidden-mobile' exact="true" activeClassName="is-active"><i className="fa fa-home" /></NavLink>
                <NavLink to="/book"  exact="true" activeClassName="is-active" className='nav-item'><i className="fa fa-book" /></NavLink>
                <a className='nav-item' href="tel:+441484535440"><i className="fa fa-phone" style={{marginRight: '10px'}}/><span className="is-hidden-touch"> 01484 535 440</span></a>

              </div>
              <div className="nav-center">
                <a
                  className='nav-item'
                  href="https://www.instagram.com/BotafogoGrill"
                  target="_blank"><i className="fa fa-instagram" /></a>
                <a
                  className='nav-item'
                  href="https://www.facebook.com/BotafogoGrillHuddersfield/"
                  target="_blank"><i className="fa fa-facebook" /></a>
                  <a className='nav-item'
                    href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                <a
                  className='nav-item'
                  href="https://twitter.com/BotafogoGrill"
                  target="_blank"><i className="fa fa-twitter" /></a>

              </div>
              <span className={`nav-toggle ${isActive && 'is-active'}`} onClick={this.toggleActiveState}>
                <span></span>
                <span></span>
                <span></span>
              </span>
              <div className={`nav-right nav-menu is-active ${isActive && 'nav-active'}`}>
                <NavLink  strict="true" activeClassName="is-active" to="/" className='nav-item is-hidden-tablet'>Home</NavLink>
                <NavLink  strict="true" activeClassName="is-active" to="/menus" className='nav-item'>Menus</NavLink>
                <NavLink  exact="true" activeClassName="is-active" to="/find-us" className='nav-item '>Find Us</NavLink>
              </div>
            </div>
          </header>
        </div>
    )
  }

}
