import React from 'react'
import PropTypes from 'prop-types'

import './Section.scss'

export default class Section extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { name, children, className, style } = this.props
    return (
      <section id={name} className={className} style={style}>
        {children}
      </section>
    )
  }
}
