import React, { useState, useEffect, useCallback } from 'react'
import './Home.scss'
import Nav from '../../components/Nav'
import Section from '../../components/Section'
import Button from '../../components/Button'

const Slide = ({ visible, showButton = true, title, subtitle }) => (
  <div>
    <div className={`hero-body ${visible ? 'is-visible' : ''}`}>
      <div className="container is-desktop">
        <h1 className="title">
          {title}
        </h1>
        <h2 className="subtitle">
          {subtitle}
        </h2>
        {showButton && (
          <Button internal href="/book" className="has-shadow is-danger" text="Book a table" />
        )}
      </div>
    </div>
  </div>
)
const Home = ({ classNames = '', menu, slides = [] }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [visible, setVisible] = useState(false);

  const changeSlide = useCallback(() => {
    const totalSlides = slides.length;
    if (totalSlides < 2) return;
  
    const nextSlide = currentPosition < totalSlides - 1 ? currentPosition + 1 : 0;

    setVisible(false)
    setCurrentPosition(nextSlide);

    setVisible(true)
  }, [currentPosition, setCurrentPosition])

  useEffect(() => {
    setTimeout(() => setVisible(true), 200);

    const interval = setInterval(() => changeSlide(), 10000)

    return () => {
      clearInterval(interval)
    }
  }, [changeSlide])

  const currentSlide = slides[currentPosition] || {};
  const backgroundImage = currentSlide?.backgroundImage?.childImageSharp?.fluid?.src || '';

  return (
    <div>
      <Nav />
      <Section
        name="home"
        className={`"hero is-medium ${classNames}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
          <div className="hero-bota-nav">
            <div className="container">
              <img src="/images/bota-logo.png" alt="Botafogo Logo" />
            </div>
          </div>

          {
            menu ? (
              <div className="hero-footer">
                <div className="container is-desktop has-text-centered">
                    <Slide {...currentSlide} visible />
                </div>
              </div>
            ) : (
            <Slide {...currentSlide} visible={visible} />
            )
          }
      </Section>
    </div>
  )
}

export default Home
