import React from 'react'
import {
  Link
} from 'gatsby'
import PropTypes from 'prop-types'
import './Button.scss'

export default class Button extends React.Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    icon: PropTypes.string,
    internal: PropTypes.bool,
    className: PropTypes.string
  }

  static defaultProps = {
    internal: false
  }

  renderInternalLink = () => {
    const { href, className, text, icon} = this.props
    return (
      <Link to={href} className={`button ${className}`}>
          {text}
          {icon ? <i className={`fa ${icon}`}></i> : null}
      </Link>
    )
  }

  renderExternalLink = () => {
    const { href, className, text, icon} = this.props
    return (
      <a href={href} className={`button ${className}`}>
          {text}
          {icon ? <i className={`fa ${icon}`}></i> : null}
      </a>
    )
  }

  render() {
    const {
      internal
    } = this.props;

    return internal ? this.renderInternalLink() : this.renderExternalLink()
  }
}
